import React from 'react'
import { Navbar } from '../../container'

const PageNotFound = () => {
  return (
    <>
        <Navbar />
        404 : Page Not Found
    </>
  )
}

export default PageNotFound